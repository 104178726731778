export default {
  // 登录页
  loginPage: {
    area: 'エリアコード',
    areaMsg: 'エリアコードを選択してください',
    phone: '携帯電話番号',
    phoneMsg: '携帯電話番号を入力してください',
    name: 'ニックネーム',
    nameMsg: 'ニックネームを入力してください',
    password: 'パスワード',
    passwordMsg: 'パスワードを入力してください',
    confirmPassword: 'パスワード確認',
    confirmpsd: 'パスワード確認', // 注意：这里与confirmPassword重复，建议统一
    code: '招待コード',
    codeMsg: '招待コードを入力してください',
    termes: '登録は当社の利用規約に同意を意味します',
    rappelez: 'ログイン情報を記憶する',
    register: '登録',
    login: 'ログイン',
    account: '既存のアカウントを持ちますか？',
    gologing: 'ログインへ',
    noAccount: 'アカウントを持ちませんか？',
    goregister: '登録へ',
    pasdMage: 'パスワードは6-20文字の英数字で入力してください',
    loginSuccess: 'ログイン成功',
    registerSuccess: '登録成功、ログインしてください',
    clause: '利用規約に同意をチェックしてください！',
    noPasd: 'パスワードが一致しません！'
  },
  // 区号选择
  areaPage: {
    searchMsg: '国際エリアコードを入力してください',
    sub: '決定'
  },
  // tabbar页
  tabbarPage: {
    home: 'ホーム',
    order: '注文',
    trade: '取引',
    team: 'チーム',
    user: 'ユーザー'
  },
  // 首页
  homePage: {
    income: '累計収益',
    myService: 'マイサービス',
    about: '会社情報',
    partner: 'パートナー',
    title: '取引アシスタント',
    newStart: '今すぐ開始',
    dayHot: '本日の人気商品',
    price: '価格',
    daySales: "日売上",
    rate: '日増長率',
    moduleTit1: 'TikTok専用の取引アシスタント',
    moduleText1: `TikTok Shop取引アシスタントは、利益を増やすための自動取引を専門とするAI取引プラットフォームです。当プラットフォームは高度なアルゴリズムとデータ分析ツールを統合し、ユーザーが取引市場で利益を増やすのを支援いたします。`,
    moduleTit2: 'インテリジェントAI取引技術',
    moduleText2: `当社の先進AI技術を利用し、市場データを精密に分析し取引を実行し、自動化操作を実現し、取引効率を高めます。`,
    moduleTit3: '多様な取引戦略',
    moduleText3: `ユーザーのニーズに応じて多様な取引戦略を提供し、市場の変化に合わせて柔軟に戦略を調整いたします。`,
    moduleTit4: '安定したリスク管理',
    moduleText4: `厳しいリスクコントロールシステムを備え、取引の安全と安定を確保し、ユーザーの資金を不必要なリスクにさらさないようにいたします。`,
    moduleTit5: '専門のユーザーサポート',
    moduleText5: `当社の専門チームは常にユーザーに専門サポートを提供の用意をでいており、何か問題が発生した場合でも、カスタマーサービスチームは迅速に対応いたします。`,
  },
  // 订单页
  orderPage: {
    order: '注文',
    notFinished: '未完了',
    completed: '完了',
    more: 'もっと見る',
    orderNum: '注文番号',
    tradeTime: '取引時間',
    payTime: '支払い時間',
    amount: '金額',
    newTaskNum: '現在のタスク数',
    expectedReturns: '予想収益',
    noData: 'データありません',
    placeOrder: '注文を送信',
    cancel: 'キャンセル',
    close: '閉じる',
    noBalance: '残高不足',
    needRecharge: 'チャージが必要です',
    gorecharge: 'チャージ画面へ',
    over: '完了'
  },
  // 交易页
  tradePage: {
    title: '取引',
    account: 'アカウント残高',
    introduceTitle: '取引紹介',
    introduceText: 'TiktokShopは毎日TiktokShopを使用したフィードバックを利用し、TiktokShopで売上が伸びたがりの製品を展示いたします。ユーザーは注文をクリックするだけで、システムは自動的に注文署名を生成いたします。ユーザーはUSDTブロックチェーンを利用し注文金額を支払い、毎回0.6%以上の手数料を獲得し、システムはランダムで大きな報酬注文を割り当てます。',
    dayProfit: '本日利益',
    tradeTime: '取引時間',
    price: '金額',
    profit: '予想収益',
    startTrade: '取引を開始',
    close: 'キャンセル',
    tIncome: '累計タスク収益',
    cIncome: '累計手数料収益',
    match: '一度起動でマッチング',
    Completed: 'タスク完了',
    totalTask: 'タスク総数',
    income: '今回の収入',
    lrl: '利益率',
    ydlr: '予想利益',
    ppz: 'マッチング中',
    trade: '取引中'
  },
  // 团队页
  teamPage: {
    title: 'チーム',
    money: '金額',
    commission: '手数料',
    receive: '受け取り',
    myTeam: 'マイチーム',
    total: '総人数',
    user: 'ユーザー',
    contribution: '貢献（タスク）',
    status: 'ステータス'
  },
  // 我的页面
  userPage: {
    noLogin: 'ログインしていません',
    id: '番号',
    account: 'マイアカウント',
    freezeAmount: '凍結金額',
    needUop: 'チャージが必要な金額',
    withdrawal: '引き出し',
    recharge: 'チャージ',
    invitation: '友人を招待',
    invitationText: '友人が登録後、獲得する毎回の利益に対し、お客様は一定割合の手数料を獲得いたします',
    nowInvitation: '今すぐ招待',
    userInfo: '個人情報',
    financial: '資金明細',
    rechargeRecord: 'チャージ履歴',
    withdrawalRecord: '引き出し履歴',
    lang: '言語',
    out: 'ログアウト',
    copy: 'コピー成功',
    load: 'ロード中...',
    outMsg: 'ログアウトを確定しますか？',
    msg: '取引アシスタント、お役に立ちます',
    team: 'チーム',
    help: 'ヘルプ',
    customer: 'カスタマーサービス'
  },
  // 提现页面
  withdrawalPage: {
    title: '引き出し',
    mode: '支払い方法',
    address: '引き出しアドレス',
    balance: '利用可能残高',
    money: '引き出し金額',
    account: '実際に到着',
    serviceCharge: '引き出し手数料は',
    submit: '送信',
    ipt: '引き出し金額は利用可能残高を超えてはいけません',
    isSub: '待審査',
    isAddress: 'まず引き出しアドレスを紐づけしてください',
    noAccount: '引き出し可能金額不足',
  },
  // 充值页面
  rechargePage: {
    title: '充値',
    submit: '送信',
    reMode: '充値方法',
    reMoney: '充値金額',
    certificate: '認証情報のアップロード',
    moneyMsg: '金額を入力してください',
    qrCode: 'QRコードで充値',
    copyClick: 'クリックでコピー',
    copy: 'コピー成功',
    success: '充値成功',
    submitMsg: '送信成功、審査中',
    jg: '他の支払い方法が必要な場合は、カスタマーサービスに連絡してください',
    lxkf: 'カスタマーサービスに連絡',
    text1: '長時間お支払いが届かない場合は、ページをリフレッシュしてください。',
    text2: '充値に問題があれば、オンラインカスタマーサービスに連絡してください。',
    load: 'アップロード中...',
    loadSucess: 'アップロード成功',
    loadError: 'アップロードに失敗しました。もう一度お試しください',
    isPz: '証明書をアップロードしてください',
    rechargeMsg: '以下の方法を選択して同等金額を振込み、財務審査の遅延を避けてください',
    detail: '充値詳細',
    orderId: '注文番号',
    time: '時間',
    plase: 'アカウントを使用してください',
    tran: '振り込み',
    toAcc: '口座に',
    newAccount: '目前に受領した金額',
    results: '結果を取得'
  },
  //邀请好友页
  InvitePage: {
    title: '友人招待',
    InviteCode: '招待コード',
    InviteText: '友人を招待し、大きな利益を享受。招待を始めましょう！',
    copyClick: 'クリックでコピー',
    copy: 'コピー成功',
    text1: 'TikTok Shop取引アシスタントを世界中に広げ、喜びと報酬を感じてもらいましょう。',
    text2: '友人を招待すると、友人の収入の15%の報酬を獲得できます！',
    text3: '招待リンク',
    btn: '招待リンクをコピー'
  },
  // 个人信息页
  userInfoPage: {
    title: '個人情報',
    phone: '携帯電話番号',
    pasd: 'ログインパスワード',
    edit: '編集',
    yPasd: '旧パスワードを入力してください',
    yPasdMsg: '旧パスワードを空にできません！',
    newPasd: '新パスワードを入力してください',
    newPasdMsg: '新パスワードを空にできません',
    nowPasd: 'パスワード確認',
    nowPasdMsg: 'パスワード確認を空にできません',
    close: 'キャンセル',
    submit: '決定',
    address: '変更後のアドレスを入力してください',
    sucess: '引き出しアドレス変更成功',
    goLogin: 'パスワード変更成功、再ログインしてください',
  },
  // 资金明细页
  financialPage: {
    title: '資金明細',
    noMore: 'もっとありません',
    amount: '金額',
    recharge: '充値',
    orderSy: '注文利益',
    distributionSy: '配布利益',
    other: 'その他',
    payment: '注文支払い',
    withdrawal: '引き出し',
    frozen: '凍結',
    time: '時間',
    noData: 'データありません',
    refresh: 'リフレッシュ成功',
    Withdrawn: '引き出し済み',
    shsb: '審査不合格',
    shz: '審査中'
  },
  // 充值记录页面
  rechargeRecordPage: {
    tab1: 'すべて',
    tab2: '充値',
    tab3: '引き出し',
    tab4: '利益',
    title: '充値履歴',
    noMore: 'もっとありません',
    amount: '金額',
    pendingPayment: '審査中',
    paymentSec: '審査成功',
    paymentFail: '審査不合格',
    refund: '返金',
    time: '時間',
    noData: 'データありません',
    refresh: 'リフレッシュ成功'
  },
  // 提现记录
  withdrawalRecordPage: {
    title: '引き出し履歴',
    noMore: 'もっとありません',
    amount: '金額',
    withdrawal: '引き出し',
    time: '時間',
    noData: 'データありません',
    refresh: 'リフレッシュ成功'
  }
}