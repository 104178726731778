<template>
  <div :style="{ height: appHeight }">
    <router-view></router-view>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
const appHeight = ref(''); // 初始值，可以是一个字符串或一个数字（带单位）
// 你可以在这里添加逻辑来动态改变 appHeight 的值
onMounted(() => {
  // 例如，根据窗口大小设置高度
  const newHeight = window.innerHeight + 'px';
  appHeight.value = newHeight;
  // 如果你需要监听窗口大小变化来更新高度
  window.addEventListener('resize', () => {
    appHeight.value = window.innerHeight + 'px';
  });
  // 清理函数，防止内存泄漏
  return () => {
    window.removeEventListener('resize', () => {
      appHeight.value = window.innerHeight + 'px';
    });
  };
});

watch(appHeight, (newVal) => {
  appHeight.value = window.innerHeight + 'px'
});
</script>

<style>
#app {
  touch-action: pan-y;
  padding: 0;
  margin: 0;
  background-color: #f4f4f4;
  /* height: 100vh; */
  max-width: 600px;
  transform: scale3d(1, 1, 1);
  overflow: hidden;
}
</style>
