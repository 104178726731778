import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant';
import 'vant/lib/index.css';
import 'amfe-flexible/index.js'
import store from '@/store';
import { i18n, vantLocales } from '@/locales/i18n'
import VueQrcode from 'vue-qrcode'
import { Lazyload } from 'vant';
import { Uploader } from 'vant';
//对vant组件进行初始化语言设置
vantLocales(i18n.locale)
const app = createApp(App)

app.use(store)
app.use(i18n)
app.use(Vant)
app.use(Lazyload);
app.use(Uploader);
app.use(router)
app.component('vue-qrcode', VueQrcode)
app.mount('#app')
