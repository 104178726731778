export default {
  // 登录页
  loginPage: {
    area: 'Area codice',
    areaMsg: `Si prega di selezionare l'area codice`,
    phone: 'Telefono',
    phoneMsg: 'Si prega di inserire il numero di telefono',
    name: 'Nome utente',
    nameMsg: 'Si prega di inserire il nome utente',
    password: 'Password',
    passwordMsg: 'Si prega di inserire la password',
    confirmPassword: 'Conferma password',
    confirmpsd: 'Conferma password', // 注意：这个键和 confirmPassword 重复了，可能需要合并或删除一个
    code: 'Codice di riferimento',
    codeMsg: 'Si prega di inserire il codice di riferimento',
    termes: 'Registrandosi, accetti i nostri termini e condizioni',
    rappelez: 'Ricordami',
    register: 'Registrati',
    login: 'Accedi',
    account: 'Hai già un account?',
    gologing: 'Accedi',
    noAccount: 'Non hai un account?',
    goregister: 'Registrati',
    pasdMage: 'La password deve contenere da 6 a 20 lettere e cifre',
    loginSuccess: 'Accesso riuscito',
    registerSuccess: 'Registrazione riuscita, si prega di accedere',
    clause: 'Si prega di accettare i termini!',
    noPasd: 'Le password non coincidono!'
  },
  // 区号选择
  areaPage: {
    searchMsg: 'Inserisci il prefisso internazionale',
    sub: 'Conferma'
  },
  // tabbar页
  tabbarPage: {
    home: 'Home',
    order: 'Ordini',
    trade: 'Transazioni',
    team: 'Team',
    user: 'Utente',
  },
  // 首页
  homePage: {
    income: 'Guadagno Totale',
    myService: 'Il Mio Servizio',
    about: 'Chi Siamo',
    partner: 'Partner',
    title: 'Assistente di Transazione',
    newStart: 'Inizia ora',
    dayHot: 'Prodotti più venduti oggi',
    price: 'Prezzo',
    daySales: "Vendite giornaliere",
    rate: 'Tasso di crescita giornaliero',
    moduleTit1: 'Assistente di Transazione per TikTok',
    moduleText1: "TikTok Shop Assistente di Transazione è una piattaforma di trading automatico basata sull'intelligenza artificiale, che integra algoritmi avanzati e strumenti di analisi dei dati per aiutare gli utenti ad aumentare i profitti nel mercato delle transazioni.",
    moduleTit2: 'Tecnologia di Trading Intelligente AI',
    moduleText2: "Utilizzando la nostra tecnologia avanzata di intelligenza artificiale, analizziamo accuratamente i dati di mercato ed eseguiamo transazioni in modo automatico, migliorando l'efficienza delle operazioni.",
    moduleTit3: 'Strategie di Trading Diversificate',
    moduleText3: "Offriamo diverse strategie di trading per soddisfare le esigenze di utenti diversi e aggiungiamo flessibilità nelle modifiche alle strategie in seguito ai cambiamenti del mercato.",
    moduleTit4: 'Gestione del Rischio Solida',
    moduleText4: "Disponiamo di un rigoroso sistema di controllo del rischio per garantire la sicurezza e stabilità delle transazioni, evitando rischi non necessari per i fondi degli utenti.",
    moduleTit5: 'Supporto per Utenti Professionale',
    moduleText5: "Il nostro team professionale è sempre a disposizione per fornire assistenza agli utenti. Qualsiasi problema si verifichi, il nostro team di assistenza clienti è pronto a dare tempestivamente un aiuto."
  },
  // 订单页
  orderPage: {
    order: 'Ordine',
    notFinished: 'Non completato',
    completed: 'Completato',
    more: 'Non ci sono altri',
    orderNum: 'Numero di ordine',
    tradeTime: 'Ora di transazione',
    payTime: 'Ora di pagamento',
    amount: 'Importo',
    newTaskNum: 'Numero di attività corrente',
    expectedReturns: 'Reddito previsto',
    noData: 'Nessun dato disponibile',
    placeOrder: 'Inserisci ordine',
    cancel: 'Annulla',
    close: 'Chiudi',
    noBalance: 'Saldo insufficiente',
    needRecharge: 'Necessita ricarica',
    gorecharge: 'Vuoi procedere alla ricarica?',
    over: 'Completato'
  },
  // 交易页
  tradePage: {
    title: 'Transazioni',
    account: 'Saldo conto',
    introduceTitle: 'Introduzione alle transazioni',
    introduceText: `TiktokShop utilizza i feedback di TiktokShop quotidianamente per mostrare i prodotti che necessitano di miglioramenti nelle vendite. Gli utenti devono solo fare clic sull'ordine e il sistema genererà automaticamente la firma dell'ordine. Gli utenti pagano l'importo dell'ordine attraverso la blockchain USDT, ottenendo una commissione superiore al 0.6% per volta, e il sistema assegna casualmente ordini di ricompensa di grande importo.`,
    dayProfit: 'Reddito odierno',
    tradeTime: 'Ora di transazione',
    price: 'Importo',
    profit: 'Reddito previsto',
    startTrade: 'Inizia transazione',
    close: 'Annulla',
    tIncome: 'Reddito cumulativo di attività',
    cIncome: 'Reddito cumulativo di commissioni',
    match: 'Abbinamento singolo avvio',
    Completed: 'Attività completata',
    totalTask: 'Totale attività',
    income: 'Reddito di questa rotazione',
    lrl: 'Tasso di profitto',
    ydlr: 'Profitto previsto',
    ppz: 'In corso di abbinamento',
    trade: 'In corso di transazione'
  },
  // 团队页
  teamPage: {
    title: 'Team',
    money: 'Importo',
    commission: 'Commissione',
    receive: 'Ricevi',
    myTeam: 'Il mio team',
    total: 'Totale persone',
    user: 'Utente',
    contribution: 'Contributo (attività)',
    status: 'Stato'
  },
  // 我的页面
  userPage: {
    noLogin: 'Non loggato',
    id: 'ID',
    account: 'Il mio conto',
    freezeAmount: 'Importo bloccato',
    needUop: 'Importo da ricaricare',
    withdrawal: 'Prelievo',
    recharge: 'Ricarica',
    invitation: 'Invita i tuoi amici',
    invitationText: 'Per ogni profitto guadagnato dal tuo amico registrato, riceverai una commissione corrispondente',
    nowInvitation: 'Invita ora',
    userInfo: 'Informazioni personali',
    financial: 'Dettagli finanziari',
    rechargeRecord: 'Registro di ricarica',
    withdrawalRecord: 'Registro di prelievo',
    lang: 'Lingua',
    out: 'Esci dalla sessione',
    copy: 'Copiato correttamente',
    load: 'Caricamento in corso...',
    outMsg: 'Vuoi davvero uscire dalla sessione?',
    msg: 'Assistente di transazione, a tuo servizio',
    team: 'Team',
    help: 'Aiuto',
    customer: 'Assistenza clienti',
  },
  // 提现页面
  withdrawalPage: {
    title: 'Prelievo',
    mode: 'Modalità di pagamento',
    address: 'Indirizzo di prelievo',
    balance: 'Saldo disponibile',
    money: 'Importo di prelievo',
    account: 'Importo effettivo',
    serviceCharge: 'Commissione di prelievo è di',
    submit: 'Invia',
    ipt: `L'importo di prelievo non può essere superiore al saldo disponibile`,
    isSub: 'In attesa di revisione',
    isAddress: `Per favore, va' a legare l'indirizzo di prelievo prima`,
    noAccount: 'Importo disponibile per il prelievo insufficiente'
  },
  // 充值页面
  rechargePage: {
    title: 'Ricarica',
    submit: 'Invia',
    reMode: 'Modalità di ricarica',
    reMoney: 'Importo di ricarica',
    certificate: 'Carica la prova',
    moneyMsg: `Per favore, inserisci l'importo`,
    qrCode: 'Scansiona il codice QR per ricarica',
    copyClick: 'Fare clic per copiare',
    copy: 'Copiato correttamente',
    success: 'Ricarica completata',
    submitMsg: 'Invio completato, in revisione',
    jg: `Per altre modalità di pagamento, contatta l'assistenza clienti`,
    lxkf: `Contatta l'assistenza clienti`,
    text1: 'Se non ricevi il pagamento per lungo tempo, per favore, aggiorna la pagina.',
    text2: 'Per qualsiasi problema di ricarica, contatta la nostra assistenza clienti online.',
    load: 'Caricamento in corso...',
    loadSucess: 'Caricamento completato',
    loadError: 'Caricamento fallito, si prega di riprovare',
    isPz: 'Per favore, carica la prova',
    rechargeMsg: "Si prega di scegliere il seguente metodo per effettuare un bonifico di pari importo, al fine di evitare ritardi nelle verifiche finanziarie",
    detail: "Dettagli del ricarico",
    orderId: "Numero d'ordine",
    time: "Ora",
    plase: `Si prega di utilizzare l'account`,
    tran: 'Versamento in',
    toAcc: 'nel conto',
    newAccount: 'Importo ricevuto attualmente',
    results: 'Ottieni i risultati'
  },
  // 邀请好友页
  InvitePage: {
    title: 'Invitare Amici',
    InviteCode: 'Codice di Invito',
    InviteText: 'Invita amici e ricevi grandi guadagni. Invita subito!',
    copyClick: 'Fare clic per copiare',
    copy: 'Copiato con successo',
    text1: 'Insieme diffondiamo TikTok Shop Assistente di Transazioni in tutto il mondo, affinché ognuno possa provare gioia e ricompense.',
    text2: 'Invitare amici ti dà il 15% del loro guadagno!',
    text3: 'Link di Invito',
    btn: 'Copia Link di Invito'
  },
  // 个人信息页
  userInfoPage: {
    title: 'Informazioni Personale',
    phone: 'Numero di Telefono',
    pasd: 'Password di Accesso',
    edit: 'Modificare',
    yPasd: 'Inserire la vecchia password',
    yPasdMsg: 'La vecchia password non può essere vuota!',
    newPasd: 'Inserire la nuova password',
    newPasdMsg: 'La nuova password non può essere vuota',
    nowPasd: 'Confermare Password',
    nowPasdMsg: 'Conferma la password non può essere vuota',
    close: 'Annulla',
    submit: 'Conferma',
    address: 'Inserire il nuovo indirizzo',
    sucess: 'Modifica indirizzo per il prelievo riuscita',
    goLogin: 'Modifica password riuscita, si prega di effettuare nuovamente l\'accesso',
  },
  // 资金明细页
  financialPage: {
    title: 'Dettagli Finanziari',
    noMore: 'Non ci sono altri',
    amount: 'Importo',
    recharge: 'Ricarica',
    orderSy: 'Guadagno da Ordini',
    distributionSy: 'Guadagno da Distribuzione',
    other: 'Altro',
    payment: 'Pagamento Ordine',
    withdrawal: 'Prelievo',
    frozen: 'Congelato',
    time: 'Tempo',
    noData: 'Nessun dato',
    refresh: 'Aggiornamento riuscito',
    Withdrawn: 'Prelievo Eseguito',
    shsb: 'Verifica Fallita',
    shz: 'In Verifica'
  },
  // 充值记录页面
  rechargeRecordPage: {
    tab1: 'Tutti',
    tab2: 'Ricarica',
    tab3: 'Prelievo',
    tab4: 'Guadagno',
    title: 'Registro di Ricarica',
    noMore: 'Non ci sono altri',
    amount: 'Importo',
    pendingPayment: 'In Verifica',
    paymentSec: 'Verifica Riuscita',
    paymentFail: 'Verifica Fallita',
    refund: 'Rimborso',
    time: 'Tempo',
    noData: 'Nessun dato',
    refresh: 'Aggiornamento riuscito'
  },
  // 提现记录
  withdrawalRecordPage: {
    title: 'Registro di Prelievo',
    noMore: 'Non ci sono altri',
    amount: 'Importo',
    withdrawal: 'Prelievo',
    time: 'Tempo',
    noData: 'Nessun dato',
    refresh: 'Aggiornamento riuscito'
  }
}