export default {
  // 登录页
  loginPage: {
    area: 'โซนโทรศัพท์',
    areaMsg: 'กรุณາเลือกโซนโทรศัพท์',
    phone: 'โทรศัพท์',
    phoneMsg: 'กรุณາกรอกหมายเลขโทรศัพท์',
    name: 'ชื่อเล่น',
    nameMsg: 'กรุณากรອກชื่อเล่น',
    password: 'รหัสผ่าน',
    passwordMsg: 'กรุณາກรອກรหัสผ่าน',
    confirmPassword: 'ยืนยันรหัสผ่าน',
    confirmpsd: 'ยืนยันรหัสผ่าน', // 注意：这里与confirmPassword重复，可能是个错误，已保持原样翻译
    code: 'รหัสแนะนำ',
    codeMsg: 'กรุณາກรอกรหัสแนะนำ',
    termes: 'การสมัครหมายความว่าคุณยอมรับข้อกำหนดและเงื่อนໄขของเรา',
    rappelez: 'จดจำฉัน',
    register: 'สมัครสมาชิก',
    login: 'ล็อกอิน',
    account: 'มีบัญชีแล้วหรือไม่？',
    gologing: 'ไปล็ອກอິນ',
    noAccount: 'ไม่มีบัญชีหรือไม่？',
    goregister: 'ไปสมัครสมาชิก',
    pasdMage: 'รหัสผ่านต้องมี 6-20 ตัวอັກษรและตัวเลข',
    loginSuccess: 'ล็ອກอິນสำเร็จ',
    registerSuccess: 'สมัครสมาชิกสำเร็จ, กรุณາล็ອກอิน',
    clause: 'กรุณາเลือกยอมรับຂ้อกำหนด!',
    noPasd: 'รหัสผ่านไม่ตรงกัน!'
  },
  // 区号选择
  areaPage: {
    searchMsg: 'กรุณາກรอกโซนโทรศัพท์สາກล',
    sub: 'ยืนยัน'
  },
  // tabbar页
  tabbarPage: {
    home: 'หน้าหลัก',
    order: 'ออર્ดర్',
    trade: 'การค้า',
    team: 'ทีม',
    user: 'ผู้ใช้'
  },
  // 首页
  homePage: {
    income: 'ລາຍได้รวม',
    myService: 'บริการของฉัน',
    about: 'เกี่ยวกับเรา',
    partner: 'พันธมิตร',
    title: 'TikTok Shop Assistant',
    newStart: 'เริ่มทันที',
    dayHot: 'สินค้าขายดีวันนี้',
    price: 'ราคา',
    daySales: 'การขายวัน',
    rate: 'อัตราการเติบโตรายวัน',
    moduleTit1: 'TikTok Shop Assistant สร้างขึ้นเพื่อการค้าขาย',
    moduleText1: 'TikTok Shop Assistant ແມ່ນแพลตฝแอกซ์การค้าอัตโนมັດที่มุ่งเน้นการเพิ่มกำไรของผู้ໃช้ในตลาดการค้า ระบบของเรารวมเขົ້າกับอัลกอริทึมและเครื่องมือการวิเคราะห์ข้อมูลที่ทันสมัย ช່ວยผู้ใช้ในการเพิ่มกำไรในตลาดการค้า',
    moduleTit2: 'เทคโนโลยີการค้าอัตโนมັດ AI',
    moduleText2: 'ใช้เทคโนโลยี AI ที่ทันสมัยของเรา วิเคราะห์ข้อมูลตลาดที่แม่นยำและดำเนີນการการค้า ทำให้การค้าอัตโนมັດและเพิ่มประสิทธิภาพการค้า',
    moduleTit3: 'กลยุทธ์การค้าที่หลากหลาย',
    moduleText3: 'เราให้บริการกลยุทธ์การค้าหลายรูปแบบเพื่อตอบสนອງความต้องການของผู้ใช้อย่างหลากหลาย และปรับเปลี่ยนกลยุทธ์ได้อย่างยืดหยุ่นตามการเปลี่ยนแปลงของตลาด',
    moduleTit4: 'การគ្រប់គ្រងความเสี่ยงที่มั่นคง',
    moduleText4: 'เรามີระบบควบคุมความเสี่ยงที่เข้มงวด เราຮັບປະກັນการค้าที่ปลอดภัยและมั่นคົງ หลีกเลີນการเผชิญกับความเสี่ยงที่ไม่จำเป็นของทุนผู้ใช้',
    moduleTit5: 'การสนับสนุนผู้ใชอย่างเป็นมืออาชีพ',
    moduleText5: 'ทีมงานมืออาชีพของเราพร้อมที่จะให้การสนับสนุนผู้ใชอย່າງเป็นมืออาชีพ ຖ້າคุณพบปัญหาใด ๆ ทีมงานบริการลูกค้าของเราจะให้ความช่วยเหลືອทันที',
  },
  // 订单页
  orderPage: {
    order: 'ออర్ดร์',
    notFinished: 'ยังမပြီး',
    completed: 'สมบูรณ์',
    more: 'ไม่มีข้อมูลเพิ่มเติม',
    orderNum: 'หมายเลขออร์ดร์',
    tradeTime: 'เวลาการค้า',
    payTime: 'เวลาชำระเงิน',
    amount: 'จำนวนเงิน',
    newTaskNum: 'จำนวนงานปัจจุบัน',
    expectedReturns: 'ผลตอบแทนที่คาดหวັງ',
    noData: 'ไม่มีข้อมูล',
    placeOrder: 'ส่งออร์ดร์',
    cancel: 'ยกเลิก',
    close: 'ปิด',
    noBalance: 'ยอดเงินไม่เพียงพอ',
    needRecharge: 'ต้องชำระเงิน',
    gorecharge: 'ไปชำระเงินหรือไม่',
    over: 'เสร็จสิ้น'
  },
  // 交易页
  tradePage: {
    title: 'การค้า',
    account: 'ยอดเงินบัญชี',
    introduceTitle: 'แนะนำการค้า',
    introduceText: 'TiktokShop ใช้ข้อมูลที่ได้รับจาก TiktokShop ทุกวัน đểแสดงสินค้าที่ TiktokShop ขายและต้องปรับปรุง ผู้ใช้ພຽງแค่คลิกออర్ดร์ ระบบจะสร้างลายเซ็นออร์ดร์อัตโนมັດ ผู้ใช้ชำระเงินจำนวนเงินออร์ดร์ผ่านบล็อกเชน USDT ทุกครั้งจะได้รับค่าคอมมิชชั่น 0.6% ขึ้นไป ระบบจะแจกจ່າยออร์ดร์ราប់ฝាក់รายใหญ่แบบสุ่ม',
    dayProfit: 'ผลตອບแทนวันนี้',
    tradeTime: 'เวลาการค้า',
    price: 'จำนวนเงิน',
    profit: 'ผลตອບแทนที่คาดหวັງ',
    startTrade: 'เริ่มการค้า',
    close: 'ยกเลิก',
    tIncome: 'รายได้งานรวม',
    cIncome: 'รายได้ค่าคอมมิชชັນรวม',
    match: 'การจັບคู่ครั้งเดียว',
    Completed: 'งานเสร็จสิ້ນ',
    totalTask: 'จำนวนงานรวม',
    income: 'รายได้รอบนี้',
    lrl: 'อัตรากำไร',
    ydlr: 'กำไรที่คาดหວັງ',
    ppz: 'កំពុងจັບคู่',
    trade: 'កំពុងซื้อขาย'
  },
  // 团队页
  teamPage: {
    title: 'ทีม',
    money: 'จำนวนเงิน',
    commission: 'ค่าคอมมิชชັນ',
    receive: 'รับ',
    myTeam: 'ทีมของฉัน',
    total: 'จำนวนรวม',
    user: 'ผู้ใชอ',
    contribution: 'การทํางาน(งาน)',
    status: 'สถานะ'
  },
  // 我的页面
  userPage: {
    noLogin: 'ยังไม่ได้ล็อགิน',
    id: 'หมายเลข',
    account: 'บัญชีរបស់ខ្ញុំ',
    freezeAmount: 'จำนวนเงินที่ถูกระงັບ',
    needUop: 'จำนวนเงินที่ต้องชําระเพิ่ม',
    withdrawal: 'การถອນเงิน',
    recharge: 'การชําระเงิน',
    invitation: 'เชิญเพื่อนของคุณ',
    invitationText: 'เพื่อนที่ลงทะเบียนแล้วคุณจะได้รับค่าคอมมิชชั่นที่เท่าเทียมกับកម្រិត',
    nowInvitation: 'เชิญភ្ជាប់ทันที',
    userInfo: 'ข้อมูลส่วนตัว',
    financial: 'รายละเอียดเงิน',
    rechargeRecord: 'ประวัติการชําระเงิน',
    withdrawalRecord: 'ประวัติการถອນเงิน',
    lang: 'ภาษา',
    out: 'ออกจากระบบ',
    copy: 'สำเร็จการคัดลอก',
    load: 'กำลังโหลด...',
    outMsg: 'แน่ใจหรือไม่ว่าต้องการออกจากระบบ?',
    msg: 'ผู้ช่วยธุรกรรมให้บริการคุณ',
    team: 'ทีม',
    help: 'ជំនួយ',
    customer: 'บริการลูกค้า',
  },
  // 提现页面
  withdrawalPage: {
    title: 'การถອນเงิน',
    mode: 'รูปแบบการชําระ',
    address: 'ที่อยู่สำหรับถອນเงิน',
    balance: 'ยอดเงินที่สามารถໃຊ້ได้',
    money: 'จำนวนเงินที่ถອນ',
    account: 'จำนวนเงินที่ได้รับจริง',
    serviceCharge: 'ค่าธรรมเนียมการถອນเงินคือ',
    submit: 'ส่ง',
    ipt: 'จำนวนเงินที่ถອນไม่สามารถมากกว่ายອดเงินที่สามารถໃช້ได้',
    isSub: 'รอการตรวจสອບ',
    isAddress: 'กรุณາไปยังที่ผูกที่อยู่สำหรับถອນเงินก่อน',
    noAccount: 'ยອดเงินที่สามารถถອນได้ไม่เพียงพอ'
  },
  // 充值页面
  rechargePage: {
    title: 'การชําระเงิน',
    submit: 'ส่ง',
    reMode: 'รูปแบบการชํາระ',
    reMoney: 'จำนวนเงินที่ชําระ',
    certificate: 'อัพโหลດหลักฐาน',
    moneyMsg: 'กรุณาป้อนจำนวนเงิน',
    qrCode: 'สแกน QR Code ชําระ',
    copyClick: 'คลิกที่ນີ້เพื่อคัดลອກ',
    copy: 'สำเร็จการคัดลອກ',
    success: 'ชําระเงินสำเร็จ',
    submitMsg: 'ส่งสำเร็จ, รอการตรวจสອບ',
    jg: 'หากต้องการช่องทางชําระอื่น โปรดติดต่อบริการลูกค้า',
    lxkf: 'ติดต่อบริการลูกค้า',
    text1: 'หากยังไม่ได้รับการชําระក្នុងระยะเวລາ רבรภ โปรดรีเฟชหน้า',
    text2: 'หากมีปัญหาเกີ่ยวกับการชําระ โปรดติดต่อบริการลูกค้าออนไลน์ของเรา',
    load: 'กำลังอัพโหลด...',
    loadSucess: 'อัพโหลดสำเร็จ',
    loadError: 'อัพโหลดล้มเหลว โปรดลองอีกครั้ง',
    isPz: 'กรุณาอัพโหลดหลັກฐาน',
    rechargeMsg: 'กรุณາเลือกชําระจำนวนเงินເທົ່າกันតាមວິธีการรหัสลេខ, หลีกเลี่ยงการตรวจสອບทางการเงินล่าช้า',
    detail: 'รายละเอียดการชําระ',
    orderId: 'หมายเลขคำสั່ງ',
    time: 'เวลา',
    plase: 'กรุณາໃช້บัญชี',
    tran: 'โอนເຂົ້າ',
    toAcc: 'ไปยังบัญชี',
    newAccount: 'จำนวนเงินที่ได้รับปัจจุบัน',
    results: 'ได้รับผลลัพธ์'
  },
  // 邀请好友页
  InvitePage: {
    title: 'เชิญเพื่อน',
    InviteCode: 'รหัสเชิญ',
    InviteText: 'เชิญเพื่อน รับប្រាក់ចំណេញสูง รีบไปเชิญ',
    copyClick: 'คลิกที่ນີ້เพื่อคัดลອກ',
    copy: 'สำเร็จการคัดลອກ',
    text1: 'ลองส่ง TikTok Shop ผู้ช่วยธุรกรรมไปทั่วโลก ให้ทุกคนรู้สึกถึงความสุขและการตอบแทน',
    text2: 'เชิญเพื่อนจะได้รับ 15% ຂອງប្រាក់ចំណេញ!',
    text3: 'ลิงค์เชิญ',
    btn: 'คัดลອກลิงค์เชิญ'
  },
  // 个人信息页
  userInfoPage: {
    title: 'ข้อมูลส่วนตัว',
    phone: 'เบอร์โทรศัพท์',
    pasd: 'รหัสผ่านเข้าสู่ระบบ',
    edit: 'แก้ไข',
    yPasd: 'กรุณາป้อนรหัสผ่านเดิม',
    yPasdMsg: 'รหัสผ่านเดิมไม่អាចเว้น!',
    newPasd: 'กรุณາป้อนรหัสผ่านใหม่',
    newPasdMsg: 'รหัสผ่านใหม่មិនអាចเว้น!',
    nowPasd: 'ยืนยันรหัสผ่าน',
    nowPasdMsg: 'ยืนยันรหัสผ่านមិនអាចเว้น!',
    close: 'ยกเลิก',
    submit: 'ยืนยัน',
    address: 'กรุณາป้อนที่อยู่ที่ต้องการแก้ไข',
    sucess: 'แก้ไขที่อยู่สำหรับถອນเงินสำเร็จ',
    goLogin: 'แก้ไขรหัสผ่านสำเร็จ, กรุณາเข้าสู่ระบบอีกครั้ง',
  },
  // 资金明细页
  financialPage: {
    title: 'รายละเอียดเงิน',
    noMore: 'គ្មានข้อมูลเพิ่มເຕີม',
    amount: 'จำนวนเงิน',
    recharge: 'การชําระเงิน',
    orderSy: 'ប្រាក់ចំណេញจากคำสั່ງ',
    distributionSy: 'ប្រាក់ចំណេញពីការចែកចាយ',
    other: 'อื่นๆ',
    payment: 'ชําระเงินคำสั່ງ',
    withdrawal: 'การถອນเงิน',
    frozen: 'ถูกระงັບ',
    time: 'เวลา',
    noData: 'គ្មានข้อมูล',
    refresh: 'รีเฟชสำเร็จ',
    Withdrawn: 'បានถອນเงิน',
    shsb: 'ตรวจสອບล้มเหลว',
    shz: 'รอการตรวจสອບ'
  },
  // 充值记录页面
  rechargeRecordPage: {
    tab1: 'ทั้งหมด',
    tab2: 'การชําระเงิน',
    tab3: 'การถອນเงิน',
    tab4: 'ប្រាក់ចំណេញ',
    title: 'ประวัติการชํາระเงิน',
    noMore: 'គ្មានข้อมูลเพิ่มເຕີม',
    amount: 'จำนวนเงิน',
    pendingPayment: 'รอการตรวจสອບ',
    paymentSec: 'ตรวจสອບสำเร็จ',
    paymentFail: 'ตรวจสອບล้มเหลว',
    refund: 'การคืนเงิน',
    time: 'เวลา',
    noData: 'គ្មានข้อมูล',
    refresh: 'รีเฟชสำเร็จ'
  },
  // 提现记录
  withdrawalRecordPage: {
    title: 'ประวัติการถອນเงิน',
    noMore: 'គ្មានข้อมูลเพิ่มເຕີม',
    amount: 'จำนวนเงิน',
    withdrawal: 'การถອນเงิน',
    time: 'เวลา',
    noData: 'គ្មានข้อมูล',
    refresh: 'รีเฟชสำเร็จ'
  }
}